import React, { useState, useEffect } from 'react'
import { isMobile } from 'react-device-detect'

import LoginForm from './LoginForm'
import { Section, Heading, Body, Container } from 'components'
import { useMagic, useUserStore } from 'stores'
import { navigate } from 'gatsby'

export default function Login() {
  const [mode, setMode] = useState<'createAccount' | 'signIn'>('createAccount')
  const { isAuthenticated } = useMagic()

  useEffect(() => {
    // magic.preload()
    ;(async () => {
      if (isAuthenticated) {
        navigate('/app/dashboard')
      }
    })()
  }, [isAuthenticated])

  return (
    <Section
      display="flex"
      height="100vh"
      justifyContent="center"
      alignItems="center"
      p={0}
      data-testid="login-page"
    >
      <Container maxWidth="xs">
        <div />

        <div>
          <Heading mb={2}>
            {mode === 'createAccount' ? 'Create an account' : 'Welcome back!'}
          </Heading>
          <Body mb={4}>
            {mode === 'createAccount'
              ? 'Create an account by typing in your email below'
              : 'Sign in by typing in your email below'}
          </Body>
          <LoginForm mode={mode} setMode={setMode} />
        </div>
      </Container>
    </Section>
  )
}
